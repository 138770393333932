/*
Achtung! Die range-Werte können bei den einzelnen Kategorien nicht
unterschiedlich sein. Das Feld wird intern nur 1x gebaut.
*/

export default {
  categoryList: [
    {
      name: "haeuser",
      fieldList: [
        { var: "price.value", range: "max" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.numberRooms", range: "min" },
      ]
    },
    {
      name: "wohnungen",
      fieldList: [
        { var: "price.value", range: "max" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.numberRooms", range: "min" },
      ]
    },
  ]
};
