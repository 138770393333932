<template>
<v-app>
  <contact-form :name="name" :required-fields="requiredFields">
      <contact-fields :exclude="exclude"></contact-fields>
  </contact-form>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFields";

export default {
  name: "ContactAloneFormApp",

  components: {
    ContactForm,
    ContactFields
  },

  data() {
    return {
      name: "contact"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    exclude() {
      if (this.$root.exclude) {
        return this.$root.exclude;
      }

      return [];
    }
  }
};
</script>
