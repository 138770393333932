<template>
<div>
  <div class="contact__info">
    <div class="first-col">
      <span class="fas fa-home"></span>
    </div>

    <div>
      <div>
        <h3>{{ company.name }}</h3>

        <ul class="text-small list-unstyled mb-1">
          <li>
            <span>Adresse:</span>
            {{ company.street }}, {{ company.zip }} {{ company.city }}
          </li>

          <li>
            <span>Telefon:</span>
            <a :href="'tel:' + company.phone">
                {{ company.phone }}
            </a>
          </li>

          <li>
            <span>E-Mail:</span>
            <a :href="'mailto:' + company.email">
              E-Mail senden</a>
          </li>
        </ul>

        <a :href="'https://www.google.de/maps?daddr=' + company.street + ', ' + company.zip + '+' + company.city + '&saddr='"
            title="Route jetzt berechnen"
            target="_blank"
            class="link--arrow text-normal mb-1">
              Route jetzt berechnen
        </a>
      </div>
    </div>
  </div>

  <div class="contact__info">
    <div class="first-col">
      <span class="fas fa-horse"></span>
    </div>

    <div>
      <div>

        <h3>Ihr ResthofExperte</h3>

        <p class="text-small">
          Sprechen Sie uns an – unser ResthofExperte Jürgen Jahnke berät Sie gerne rund um die Vermittlung der Rest- und Reiterhöfe.
        </p>

        <p>
            <a href="/resthoefe/"
               title="zum ResthofExperten"
               target="_blank"
               class="link--arrow text-normal mb-1">
                zum ResthofExperten
          </a>
        </p>

      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ContactCompanyInfo",

  computed: {
    company() {
      return this.$yeti.company();
    }
  },

  methods: {
    onMapLoad(o) {
      o.L.marker(o.defaultCenter).addTo(o.map);
      this.mapCenter = o.defaultCenter;
    }
  }
}
</script>
